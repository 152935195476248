'use client';

import { useLatest } from '@komo-tech/core/hooks/useLatest';
import { Guid } from '@komo-tech/core/models/Guid';
import { NoOp } from '@komo-tech/core/utils/NoOp';
import { useQuery } from '@tanstack/react-query';
import { PrefetchKind } from 'next/dist/client/components/router-reducer/router-reducer-types';
import { PrefetchOptions, Url } from 'next/dist/shared/lib/router/router';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { NextRouter } from 'next/router';
import { decode } from 'querystring';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'url';

import {
  AdminPortalProps,
  AdminPortalProvider
} from '@/admin/components/AdminPortalStore';
import { usePostHogAdminFunctions } from '@/admin/components/posthog/usePostHogAdminFunctions';
import { useCompanyData } from '@/admin/components/shared/Companies/_useCompanyData';
import { useCompanyOptionsData } from '@/admin/components/shared/Companies/_useCompanyOptionsData';
import { AdminAdministratorService } from '@/admin/data/AdminAdministratorService';
import { AdminCache } from '@/admin/data/AdminCache';
import { AdminCompanyService } from '@/admin/data/AdminCompanyService';
import { AdminWorkspaceService } from '@/admin/data/Workspaces';
import { useCheckAdministratorSession } from '@/admin/hooks/useAdministratorCheckSession';
import { UserflowService } from '@/admin/utils/UserflowService';
import {
  LoadingPage,
  LoadingPageProps
} from '@/common/components/Pages/LoadingPage';
import { OptionalDomPortalProvider } from '@/common/components/Portal/DomPortalProvider';
import { DataDogService } from '@/common/utils/DataDogService';

interface AdminPortalProvidersProps
  extends Pick<AdminPortalProps, 'withShortcuts' | 'withSpotlight'> {
  children: ReactNode;
  withDomPortalProvider?: boolean;
  loadingProps?: Pick<
    LoadingPageProps,
    'forceColorScheme' | 'withThemeProvider'
  >;
}

export const AdminPortalProviders = ({
  children,
  withDomPortalProvider = true,
  withShortcuts,
  withSpotlight,
  loadingProps
}: AdminPortalProvidersProps) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const query = decode(searchParams.toString());
  // Mapping the app router to NextRouter to work with old components while we migrate
  // https://nextjs.org/docs/app/building-your-application/upgrading/app-router-migration#step-5-migrating-routing-hooks
  const nextRouter: NextRouter = useMemo(() => {
    return {
      pathname,
      query,
      route: '',
      asPath: pathname + '?' + searchParams.toString(),
      basePath: '',
      isLocaleDomain: true,
      push: (url: Url, as?: Url, options?: TransitionOptions) => {
        router.push(format(url), { scroll: options?.scroll });
        return Promise.resolve(true);
      },
      replace: (url: Url, as?: Url, options?: TransitionOptions) => {
        router.replace(format(url), { scroll: options?.scroll });
        return Promise.resolve(true);
      },
      reload: router.refresh,
      back: router.back,
      forward: router.forward,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      prefetch: (url: Url, _as?: Url, _options?: PrefetchOptions) => {
        router.prefetch(format(url), { kind: PrefetchKind.AUTO });
        return Promise.resolve();
      },
      beforePopState: NoOp,
      events: null,
      isFallback: false,
      isPreview: false,
      isReady: true
    };
  }, [router, pathname, searchParams]);
  const routerRef = useLatest(nextRouter);
  const [isLocalRead, setIsLocalRead] = useState(false);

  const goToLogin = useCallback(
    () => router.push('/portal/auth/login'),
    [router]
  );

  const administratorQuery = useQuery(
    AdminAdministratorService.cacheKeys.admin(AdminCache.sessionId),
    () =>
      AdminAdministratorService.getAdministratorFromSessionAsync(
        AdminCache.sessionId!
      ),
    {
      enabled: !!AdminCache.sessionId && isLocalRead,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: () => goToLogin()
    }
  );

  const { company, companyLoading } = useCompanyData(AdminCache.companyId, {
    enabled: isLocalRead,
    onError: goToLogin
  });

  //surely no one will have more than 200 workspaces?
  const { data: workspaces, isLoading: workspacesLoading } =
    AdminWorkspaceService.hooks.useSearch({
      pageSize: 200
    });

  const [companyOptions, companyOptionsLoading] = useCompanyOptionsData(
    AdminCache.companyId
  );

  const posthogFunctions = usePostHogAdminFunctions();

  useEffect(() => {
    UserflowService.init();
  }, []);

  useEffect(() => {
    if (window === undefined) {
      return;
    }
    AdminCache.load();
    if (!AdminCache.sessionId) {
      // noinspection JSIgnoredPromiseFromCall
      goToLogin();
      return;
    }
    setIsLocalRead(true);
  }, [goToLogin]);

  useEffect(() => {
    if (
      administratorQuery.data === undefined ||
      !administratorQuery.isSuccess
    ) {
      return;
    }

    DataDogService.setAdministrator({
      id: administratorQuery.data.id.toString(),
      name: administratorQuery.data.name,
      email: administratorQuery.data.email,
      adminType: administratorQuery.data.type
    });

    posthogFunctions.identifyAdmin(administratorQuery.data);

    UserflowService.identifyAdmin(administratorQuery.data);

    try {
      if (window) {
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
          'identify',
          {
            email: administratorQuery.data.email,
            name: administratorQuery.data.name
          }
        ]);
      }
    } catch (e) {
      console.error(e);
    }
  }, [administratorQuery.data, administratorQuery.isSuccess]);

  const shouldLoadCompanies =
    !AdminCache.companyId &&
    !!administratorQuery.data &&
    administratorQuery.isSuccess;

  useQuery(
    AdminCompanyService.cacheKeys.search(1, 0),
    async () => {
      const companies = await AdminCompanyService.searchAsync({ take: 1 });
      if (!AdminCache.companyId && companies.items.length > 0) {
        AdminCache.setCompany(new Guid(companies.items[0].id));
      }
      return companies;
    },
    {
      enabled: shouldLoadCompanies,
      onError: (err) => console.error(err)
    }
  );

  useCheckAdministratorSession(router.push);

  const hasLoaded =
    !!administratorQuery.data &&
    !!AdminCache.companyId &&
    administratorQuery.isSuccess &&
    company &&
    companyOptions &&
    !companyLoading &&
    !companyOptionsLoading &&
    !workspacesLoading &&
    !!workspaces;

  if (!hasLoaded) {
    return <LoadingPage {...loadingProps} variant="komo" />;
  }

  return (
    <AdminPortalProvider
      withShortcuts={withShortcuts}
      withSpotlight={withSpotlight}
      administrator={administratorQuery.data}
      company={company}
      companyOptions={companyOptions}
      workspaces={workspaces.items}
      workspace={
        AdminCache.workspaceId
          ? workspaces.items.find((w) => w.id.equals(AdminCache.workspaceId))
          : workspaces.items[0]
      }
      routerRef={routerRef}
      pathname={pathname}
      query={query}
      isUsingAppRouter={true}
    >
      <OptionalDomPortalProvider hide={!withDomPortalProvider}>
        {children}
      </OptionalDomPortalProvider>
    </AdminPortalProvider>
  );
};

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}
