import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { usePostHog } from 'posthog-js/react';

import { Administrator } from '@/admin/models/Administrator';
import { Company } from '@/common/models/companies/Company';

export const usePostHogAdminFunctions = () => {
  const posthog = usePostHog();

  const identifyAdmin = useEvent((admin: Administrator) => {
    if (!admin?.id) return;

    posthog?.identify(admin.id.toString(), {
      email: admin.email,
      name: admin.name,
      adminType: admin.type
    });
  });

  const associateWithCompany = useEvent((company: Company) =>
    posthog?.group('company', company.id.toString(), {
      name: company.name
    })
  );

  const trackPageView = useEvent(() => posthog?.capture('$pageview'));

  return { identifyAdmin, trackPageView, associateWithCompany } as const;
};
