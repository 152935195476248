'use client';

// We can not useState or useRef in a server component, which is why we are
// extracting this part out into its own file with 'use client' on top
import { OptionalThemeProvider, ThemeProviderProps } from '_theme/_Provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { KomoPostHogProvider } from '@/admin/components/posthog/KomoPostHogProvider';
import { OptionalToasterProvider } from '@/common/components/Toast/ToasterProvider';
import { ToastPosition } from '@/common/components/Toast/ToastPosition';

import { init } from '../../init';

init();

export interface AdminProvidersProps
  extends Pick<ThemeProviderProps, 'forceColorScheme'> {
  children: React.ReactNode;
  withThemeProvider?: boolean;
}

const PostHogPageView = dynamic(() => import('../PostHogPageView'), {
  ssr: false
});

export const AdminProviders = ({
  children,
  forceColorScheme,
  withThemeProvider = true
}: AdminProvidersProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
            onError: (error) => console.error(error)
          },
          mutations: {
            onError: (error) => console.error(error)
          }
        }
      })
  );

  useEffect(() => {
    if (document) {
      (Modal as any).setAppElement('body');
    }
  }, []);

  return (
    <KomoPostHogProvider>
      <QueryClientProvider client={queryClient}>
        <OptionalThemeProvider
          withTheme={withThemeProvider}
          forceColorScheme={forceColorScheme}
        >
          <OptionalToasterProvider
            hide={!withThemeProvider}
            position={ToastPosition.BottomLeft}
          >
            <PostHogPageView />
            {children}
          </OptionalToasterProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </OptionalThemeProvider>
      </QueryClientProvider>
    </KomoPostHogProvider>
  );
};
