import { useEvent } from '@komo-tech/core/hooks/useEvent';
import { Guid } from '@komo-tech/core/models/Guid';
import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash/isNil';

import { usePostHogAdminFunctions } from '@/admin/components/posthog/usePostHogAdminFunctions';
import { AdminCache } from '@/admin/data/AdminCache';
import { AdminCompanyService } from '@/admin/data/AdminCompanyService';

interface Options {
  enabled?: boolean;
  onError?: (error: any) => void;
}

export const useCompanyData = (
  companyId: Guid | undefined,
  options?: Options
) => {
  const cacheKey = AdminCompanyService.cacheKeys.company(companyId);
  const postHogFunctions = usePostHogAdminFunctions();

  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    cacheKey,
    async () => {
      const company = await AdminCompanyService.getAsync(companyId!);
      if (!AdminCache.workspaceId) {
        AdminCache.setWorkspace(company.workspaces[0].id);
      }
      postHogFunctions.associateWithCompany(company);
      return company;
    },
    {
      enabled:
        !!companyId && (isNil(options?.enabled) ? true : options!.enabled),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (e) => {
        console.error(e);
        options?.onError?.(e);
      }
    }
  );

  const refetchAsync = useEvent(() => refetch());

  return {
    company: data,
    companyLoading: isLoading,
    isFetching,
    isError,
    onInvalidateCompanyAsync: refetch,
    refetchAsync
  };
};
